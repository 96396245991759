import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useState } from "react";

export default function App(props){
    const [wyjscie, setWyjscie] = useState({y1: false, y2: false, y3: false});
    const [klik, setKlik] = useState({A: false, B: false, C: false, D: false, E: false});
    const tablicaPrawdy = [
        {
            "A": 0,
            "B": 0,
            "C": 0,
            "D": 0,
            "E": 0,
            "y1": 0,
            "y2": 0,
            "y3": 0
        },
        {
            "A": 0,
            "B": 0,
            "C": 0,
            "D": 0,
            "E": 1,
            "y1": 0,
            "y2": 1,
            "y3": 0
        },
        {
            "A": 0,
            "B": 0,
            "C": 0,
            "D": 1,
            "E": 0,
            "y1": 0,
            "y2": 1,
            "y3": 0
        },
        {
            "A": 0,
            "B": 0,
            "C": 0,
            "D": 1,
            "E": 1,
            "y1": 1,
            "y2": 0,
            "y3": 1
        },
        {
            "A": 0,
            "B": 0,
            "C": 1,
            "D": 0,
            "E": 0,
            "y1": 0,
            "y2": 1,
            "y3": 0
        },
        {
            "A": 0,
            "B": 0,
            "C": 1,
            "D": 0,
            "E": 1,
            "y1": 1,
            "y2": 1,
            "y3": 0
        },
        {
            "A": 0,
            "B": 0,
            "C": 1,
            "D": 1,
            "E": 0,
            "y1": 0,
            "y2": 0,
            "y3": 1
        },
        {
            "A": 0,
            "B": 0,
            "C": 1,
            "D": 1,
            "E": 1,
            "y1": 0,
            "y2": 1,
            "y3": 1
        },
        {
            "A": 0,
            "B": 1,
            "C": 0,
            "D": 0,
            "E": 0,
            "y1": 1,
            "y2": 0,
            "y3": 0
        },
        {
            "A": 0,
            "B": 1,
            "C": 0,
            "D": 0,
            "E": 1,
            "y1": 0,
            "y2": 0,
            "y3": 1
        },
        {
            "A": 0,
            "B": 1,
            "C": 0,
            "D": 1,
            "E": 0,
            "y1": 0,
            "y2": 0,
            "y3": 1
        },
        {
            "A": 0,
            "B": 1,
            "C": 0,
            "D": 1,
            "E": 1,
            "y1": 0,
            "y2": 1,
            "y3": 1
        },
        {
            "A": 0,
            "B": 1,
            "C": 1,
            "D": 0,
            "E": 0,
            "y1": 0,
            "y2": 1,
            "y3": 0
        },
        {
            "A": 0,
            "B": 1,
            "C": 1,
            "D": 0,
            "E": 1,
            "y1": 1,
            "y2": 0,
            "y3": 1
        },
        {
            "A": 0,
            "B": 1,
            "C": 1,
            "D": 1,
            "E": 0,
            "y1": 1,
            "y2": 0,
            "y3": 1
        },
        {
            "A": 0,
            "B": 1,
            "C": 1,
            "D": 1,
            "E": 1,
            "y1": 1,
            "y2": 1,
            "y3": 1
        },
        {
            "A": 1,
            "B": 0,
            "C": 0,
            "D": 0,
            "E": 0,
            "y1": 1,
            "y2": 0,
            "y3": 0
        },
        {
            "A": 1,
            "B": 0,
            "C": 0,
            "D": 0,
            "E": 1,
            "y1": 0,
            "y2": 0,
            "y3": 1
        },
        {
            "A": 1,
            "B": 0,
            "C": 0,
            "D": 1,
            "E": 0,
            "y1": 0,
            "y2": 0,
            "y3": 1
        },
        {
            "A": 1,
            "B": 0,
            "C": 0,
            "D": 1,
            "E": 1,
            "y1": 0,
            "y2": 1,
            "y3": 1
        },
        {
            "A": 1,
            "B": 0,
            "C": 1,
            "D": 0,
            "E": 0,
            "y1": 0,
            "y2": 1,
            "y3": 0
        },
        {
            "A": 1,
            "B": 0,
            "C": 1,
            "D": 0,
            "E": 1,
            "y1": 1,
            "y2": 0,
            "y3": 1
        },
        {
            "A": 1,
            "B": 0,
            "C": 1,
            "D": 1,
            "E": 0,
            "y1": 1,
            "y2": 0,
            "y3": 1
        },
        {
            "A": 1,
            "B": 0,
            "C": 1,
            "D": 1,
            "E": 1,
            "y1": 1,
            "y2": 1,
            "y3": 1
        },
        {
            "A": 1,
            "B": 1,
            "C": 0,
            "D": 0,
            "E": 0,
            "y1": 0,
            "y2": 1,
            "y3": 0
        },
        {
            "A": 1,
            "B": 1,
            "C": 0,
            "D": 0,
            "E": 1,
            "y1": 1,
            "y2": 0,
            "y3": 1
        },
        {
            "A": 1,
            "B": 1,
            "C": 0,
            "D": 1,
            "E": 0,
            "y1": 1,
            "y2": 1,
            "y3": 0
        },
        {
            "A": 1,
            "B": 1,
            "C": 0,
            "D": 1,
            "E": 1,
            "y1": 0,
            "y2": 1,
            "y3": 1
        },
        {
            "A": 1,
            "B": 1,
            "C": 1,
            "D": 0,
            "E": 0,
            "y1": 0,
            "y2": 0,
            "y3": 1
        },
        {
            "A": 1,
            "B": 1,
            "C": 1,
            "D": 0,
            "E": 1,
            "y1": 0,
            "y2": 1,
            "y3": 1
        },
        {
            "A": 1,
            "B": 1,
            "C": 1,
            "D": 1,
            "E": 0,
            "y1": 0,
            "y2": 1,
            "y3": 1
        },
        {
            "A": 1,
            "B": 1,
            "C": 1,
            "D": 1,
            "E": 1,
            "y1": 1,
            "y2": 1,
            "y3": 1
        }
    ];

    const ktoreWyjscie = (ktore) => {
        const odp = tablicaPrawdy.filter(v => v.A == klik.A && v.B == klik.B && v.C == klik.C && v.D == klik.D && v.E == klik.E)
        switch(ktore){
            case 'y1':
                return odp[0].y1;
            case 'y2':
                return odp[0].y2;
            case 'y3':
                return odp[0].y3;
            default:
                return false;
        }
    };

    return(
        <div style={{background: '#fff', padding: 20}}>
        {/* <h3 style={{color: '#1c1c1c', textShadow: '1px 1px 3px #aaa', marginBottom: '10px'}}>Wykres symulacji schematu logicznego y1(1)</h3> */}
        {wyjscie.y1 ? <div className='wykres wyjsciowa'>
            <div className='tytul'>y1</div>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={tablicaPrawdy}>
                    <CartesianGrid strokeDasharray="3 3" opacity={.85} />
                    <XAxis tickFormatter={(v, i) => v+1} />
                    <YAxis domain={[0, 1]} interval={3} />
                    <Line type="step" dataKey="y1" stroke="#1c1c1c" dot={{stroke: '#222', strokeWidth: 1, fill: '#ccc'}} />
                </LineChart>
            </ResponsiveContainer>
        </div> : ""}
        {wyjscie.y2 ? <div className='wykres wyjsciowa'>
            <div className='tytul'>y2</div>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={tablicaPrawdy}>
                    <CartesianGrid strokeDasharray="3 3" opacity={.85} />
                    <XAxis tickFormatter={(v, i) => v+1} />
                    <YAxis domain={[0, 1]} interval={3} />
                    <Line type="step" dataKey="y2" stroke="#1c1c1c" dot={{stroke: '#222', strokeWidth: 1, fill: '#ccc'}} />
                </LineChart>
            </ResponsiveContainer>
        </div> : ""}
        {wyjscie.y3 ? <div className='wykres wyjsciowa'>
            <div className='tytul'>y3</div>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={tablicaPrawdy}>
                    <CartesianGrid strokeDasharray="3 3" opacity={.85} />
                    <XAxis tickFormatter={(v, i) => v+1} />
                    <YAxis domain={[0, 1]} interval={3} />
                    <Line type="step" dataKey="y3" stroke="#1c1c1c" dot={{stroke: '#222', strokeWidth: 1, fill: '#ccc'}} />
                </LineChart>
            </ResponsiveContainer>
        </div> : ""}
        <div className='wykres'>
            <div className='tytul'>A</div>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={tablicaPrawdy} >
                <CartesianGrid strokeDasharray="3 3" opacity={.85} />
                <XAxis tickFormatter={(v, i) => v+1} />
                <YAxis domain={[0, 1]} interval={3} />
                <Line type="step" dataKey="A" stroke="#1c1c1c" dot={{stroke: '#222', strokeWidth: 1, fill: '#ccc'}} />
                </LineChart>
            </ResponsiveContainer>
        </div>
        <div className='wykres'>
            <div className='tytul'>B</div>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={tablicaPrawdy} >
                <CartesianGrid strokeDasharray="3 3" opacity={.85} />
                <XAxis tickFormatter={(v, i) => v+1} />
                <YAxis domain={[0, 1]} interval={3} />
                <Line type="step" dataKey="B" stroke="#1c1c1c" dot={{stroke: '#222', strokeWidth: 1, fill: '#ccc'}} />
                </LineChart>
            </ResponsiveContainer>
        </div>
        <div className='wykres'>
            <div className='tytul'>C</div>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={tablicaPrawdy} >
                <CartesianGrid strokeDasharray="3 3" opacity={.85} />
                <XAxis tickFormatter={(v, i) => v+1} />
                <YAxis domain={[0, 1]} interval={3} />
                <Line type="step" dataKey="C" stroke="#1c1c1c" dot={{stroke: '#222', strokeWidth: 1, fill: '#ccc'}} />
                </LineChart>
            </ResponsiveContainer>
        </div>
        <div className='wykres'>
            <div className='tytul'>D</div>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={tablicaPrawdy} >
                <CartesianGrid strokeDasharray="3 3" opacity={.85} />
                <XAxis tickFormatter={(v, i) => v+1} />
                <YAxis domain={[0, 1]} interval={3} />
                <Line type="step" dataKey="D" stroke="#1c1c1c" dot={{stroke: '#222', strokeWidth: 1, fill: '#ccc'}} />
                </LineChart>
            </ResponsiveContainer>
        </div>
        <div className='wykres'>
            <div className='tytul'>E</div>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={tablicaPrawdy} >
                <CartesianGrid strokeDasharray="3 3" opacity={.85}/>
                <XAxis tickFormatter={(v, i) => v+1} />
                <YAxis domain={[0, 1]} interval={3} />
                <Line type="step" dataKey="E" stroke="#1c1c1c" dot={{stroke: '#222', strokeWidth: 1, fill: '#ccc'}}/>
                </LineChart>
            </ResponsiveContainer>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div className='przyciski' style={{display: 'flex', gap: '10px'}}>
                <button style={{background: `${wyjscie.y1 ? "#2d2" : "#d22"}`}} onClick={() => setWyjscie({...wyjscie, y1: !wyjscie.y1})}>Sygnał y1</button>
                <button style={{background: `${wyjscie.y2 ? "#2d2" : "#d22"}`}} onClick={() => setWyjscie({...wyjscie, y2: !wyjscie.y2})}>Sygnał y2</button>
                <button style={{background: `${wyjscie.y3 ? "#2d2" : "#d22"}`}} onClick={() => setWyjscie({...wyjscie, y3: !wyjscie.y3})}>Sygnał y3</button>
            </div>
            <div className='klikalne' style={{display: 'flex', gap: '10px'}}>
            <button style={{background: `${klik.A ? "green" : "red"}`}} onClick={() => setKlik({...klik, A: !klik.A})}>A</button>
            <button style={{background: `${klik.B ? "green" : "red"}`}} onClick={() => setKlik({...klik, B: !klik.B})}>B</button>
            <button style={{background: `${klik.C ? "green" : "red"}`}} onClick={() => setKlik({...klik, C: !klik.C})}>C</button>
            <button style={{background: `${klik.D ? "green" : "red"}`}} onClick={() => setKlik({...klik, D: !klik.D})}>D</button>
            <button style={{background: `${klik.E ? "green" : "red"}`}} onClick={() => setKlik({...klik, E: !klik.E})}>E</button>
            <br />
            <p style={{background: `${ktoreWyjscie("y1") ? "#2f2" : "crimson"}`}}>Y1: {ktoreWyjscie("y1")}</p>
            <p style={{background: `${ktoreWyjscie("y2") ? "#2f2" : "crimson"}`}}>Y2: {ktoreWyjscie("y2")}</p>
            <p style={{background: `${ktoreWyjscie("y3") ? "#2f2" : "crimson"}`}}>Y3: {ktoreWyjscie("y3")}</p>
            </div>
        </div>
        </div>
    )
};